<template>
  <client-only>
    <div
      class="fixed z-[99999] right-4"
      :class="[!hasIntercomIntegrate ? 'bottom-5' : 'bottom-[78px]']"
      v-if="currentSiteSettings"
    >
      <LazyFloatingAppDownload v-if="isAppPage" />
      <transition name="slide-up" appear>
        <div
          v-if="isOpen || isSingle"
          :class="[
            isSingle
              ? 'border-transparent'
              : 'border-white bg-black bg-opacity-70 rounded-full pb-3.5',
          ]"
          class="flex px-1 pt-1 flex-col items-center space-y-2.5 border border-opacity-20"
        >
          <LazyFloatingMemeLink v-if="hasMessanger" />
          <LazyFloatingYoutube v-if="hasYouTube" />
          <LazyFloatingWhatsappChat
            :phone="currentSiteSettings.whatsapp"
            v-if="hasWhatsApp"
          />
          <CloseIcon
            v-if="!isSingle"
            class="w-5 h-5 text-white cursor-pointer"
            @click="isOpen = false"
          />
        </div>
        <div
          v-else-if="dynamicIcon"
          @click="isOpen = true"
          class="px-1 border border-transparent"
        >
          <component
            :is="dynamicIcon"
            class="w-[48px] h-[48px] cursor-pointer hover:text-[#FF212A] transition-all duration-75 text-black hover:scale-110"
          />
        </div>
      </transition>
    </div>
  </client-only>
</template>
<script setup>
const CloseIcon = defineAsyncComponent(() =>
  import("~/assets/svg/heroicons/close.svg?component")
);

const YtMeIcon = defineAsyncComponent(() =>
  import("~/assets/svg/floating/yt-me.svg?component")
);
const YtWhtIcon = defineAsyncComponent(() =>
  import("~/assets/svg/floating/yt-wht.svg?component")
);
const WhtMeIcon = defineAsyncComponent(() =>
  import("~/assets/svg/floating/wht-me.svg?component")
);
const YtWhtMeIcon = defineAsyncComponent(() =>
  import("~/assets/svg/floating/yt-wht-me.svg?component")
);

import { useMainStore } from "~/store";

const route = useRoute();
const mainStore = useMainStore();
const nuxtApp = useNuxtApp();

const currentSiteSettings = mainStore.currentSiteSettings;
const $i18nPath = nuxtApp.$i18nPath;
const isOpen = ref(false);

const hasIntercomIntegrate = computed(() => {
  return (
    mainStore.currentSite?.settings?.intercom?.integrate && !isStorePage.value
  );
});

const isAppPage = computed(() => {
  return route.path.startsWith($i18nPath(mainStore.pageLinks["app"]));
});

const isStorePage = computed(() => {
  return route.path.startsWith($i18nPath(mainStore.pageLinks["stores"]));
});
const hasWhatsApp = computed(() => {
  return currentSiteSettings && currentSiteSettings.whatsapp;
});
const hasMessanger = computed(() => {
  return (
    currentSiteSettings &&
    currentSiteSettings.facebook &&
    currentSiteSettings.facebook.integrate
  );
});
const hasYouTube = computed(() => {
  return (
    !isAppPage.value &&
    mainStore.currentSite?.podcast &&
    mainStore.features &&
    mainStore.features.find((_f) => _f.slug === "podcast")
  );
});
const isSingle = computed(() => {
  if (
    (!dynamicIcon.value &&
      (hasWhatsApp.value || hasYouTube.value || hasMessanger.value)) ||
    (!hasIntercomIntegrate.value && !hasWhatsAppYouTubeMessanger.value)
  ) {
    return true;
  }
  return false;
});

const dynamicIcon = computed(() => {
  if (hasWhatsAppYouTubeMessanger.value) {
    return YtWhtMeIcon;
  } else if (hasYouTubeWhatsApp.value) {
    return YtWhtIcon;
  } else if (hasYouTubeMessanger.value) {
    return YtMeIcon;
  } else if (hasWhatsAppMessanger.value) {
    return WhtMeIcon;
  } else {
    return false;
  }
});
const hasWhatsAppYouTubeMessanger = computed(() => {
  return hasWhatsApp.value && hasYouTube.value && hasMessanger.value;
});
const hasYouTubeWhatsApp = computed(() => {
  return hasWhatsApp.value && hasYouTube.value;
});
const hasYouTubeMessanger = computed(() => {
  return hasMessanger.value && hasYouTube.value;
});
const hasWhatsAppMessanger = computed(() => {
  return hasMessanger.value && hasWhatsApp.value;
});
</script>
<style >
.slide-up-enter-active {
  transition: all 0.25s ease-out;
}
.slide-up-leave-active {
  transition: all 0 ease-out;
}

.slide-up-enter-from {
  opacity: 0;
  transform: translateY(30px);
}

.slide-up-leave-to {
  opacity: 0;
  transform: translateY(-30px);
}
</style>